<template>
    <div class="me">
        <Header :active=4 :full='fullWidth'/>
        <!-- 轮播图 -->
        <el-image style="width: 100%; margin-top:74px" :src="imgs[0].url" v-if="fullWidth"></el-image>
        <el-image style="width: 100%; margin-top:52px" :src="imgs[1].url" v-if="!fullWidth"></el-image>
        <!-- <el-carousel  :height="fullWidth == true ? '400px' : '150px'">
          <el-carousel-item v-for="item in imgs" :key="item.id" >
            <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
          </el-carousel-item>
        </el-carousel> -->
        <!-- PC端 -->
        <!-- 火眼防伪 -->
        <div class="case_center" v-if="index==0" v-show="fullWidth">
            <div class="center_top">
                <span class="case_span">Successful cases</span>
                <span class="span_centet">东西协作平台</span>
                <span class="span_botton"></span>
            </div>
            <div class="case_details">
                <div class="success">
                    <img class="success_img" src="../../assets/img/case/kuan.png" alt="">
                </div>
                <div class="success_text">
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">所属行业</div>
                            <div class="left_span2">食品行业</div>
                        </div>
                        <div class="top_right">
                            <div class="left_span1">产品编码数量</div>
                            <div class="left_span2">4000万</div>
                        </div>
                    </div>
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">企业介绍</div>
                            <div class="enterprise">
                                “军民融合安全食品保障平台"是由国家工信部、国家食药局、中国农大、海军后勤部、中国农垦、中国扶贫协会、全国城市农贸中心联合会共同支持，青岛市政府、青岛食药局、黄岛区政府、黄岛食药局、青岛军民融合示范区等共同引领和指导下，于2015年8月正式成立，依托青岛军民融合食品保障有限公司承建，以“互联网+”为主要经营模式,保障军队、企事业单位、学校等集约式供给和零售端消费的“军队社会化保障、食品安全控制、“精准扶贫”服务体系建设平台”。
                            </div>
                        </div>
                    </div>
                    <!-- <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">企业需求</div>
                            <div class="enterprise">
                                需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需
                            </div>
                        </div>
                    </div>
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">项目成果</div>
                            <div class="enterprise">
                                成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- 悬浮 -->

        </div>
        <div class="case_center" v-if="index==3" v-show="fullWidth">
            <div class="center_top">
                <span class="case_span">Successful cases</span>
                <span class="span_centet">中宠</span>
                <span class="span_botton"></span>
            </div>
            <div class="case_details">
                <div class="success">
                    <img class="success_img" src="../../assets/shouye/img3.png" alt="">
                </div>
                <div class="success_text">
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">所属行业</div>
                            <div class="left_span2">宠物用品行业</div>
                        </div>
                        <div class="top_right">
                            <div class="left_span1">产品编码数量</div>
                            <div class="left_span2">3000万</div>
                        </div>
                    </div>
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">企业介绍</div>
                            <div class="enterprise">
                                中宠食品有限公司是一家中外合资企业，位于烟台市莱山经济开发区。公司总投资2500万美元，占地200000平米，下设第一工厂，第二工厂，第三工厂，第四工厂和宠物饼干加工厂，另有宠物主粮项目正在筹建中。所有工厂的设计、建设按中国出口食品标准，满足所有进口国要求，管理按日本农林水产省偶蹄类肉制品注册标准，并按照HACCP食品安全管理体系管理。烟台中宠食品股份有限公司是中国最具规模、最专业化的高端	宠物食品	加工企业，公司出口五大洲，年销十亿袋，成为名符其实的全球宠物零食领军企业。
                            </div>
                        </div>
                    </div>
                    <!-- <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">企业需求</div>
                            <div class="enterprise">
                                需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需求需
                            </div>
                        </div>
                    </div>
                    <div class="text_top">
                        <div class="top_left">
                            <div class="left_span1">项目成果</div>
                            <div class="enterprise">
                                成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成果成
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- 悬浮 -->

        </div>
        <div class="float" v-if="index==0" v-show="fullWidth">
            <div style="width:1200px;margin:0 auto;display: flex;justify-content: space-around;">
                <div class="float_img" :class="active == index ?'active' : ''" v-for="(item,index) in imgList "
                     v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)" :key="index">
                    <img class="zhai_img" :src="item.url" alt="">
                </div>
            </div>

        </div>


        <!-- 移动端样式 -->
        <div class="move_center" v-if="index==0" v-show="!fullWidth">
            <div class="movecenter_top">
                <span class="movecase_span">Successful cases</span>
                <span class="movespan_centet">东西协作平台</span>
                <span class="movespan_botton"></span>
            </div>
            <!-- 详情 -->
            <div class="move_details">
                <div class="move_success">
                    <img class="movesuccess_img" src="../../assets/img/movedetails1.png" alt="">
                </div>
                <div class="movesuccess_text">
                    <div class="movetext_top">
                        <div class="movetop_left">
                            <div class="moveleft_span1">所属行业</div>
                            <div class="moveleft_span2">食品行业</div>
                        </div>
                        <div class="movetop_right">
                            <div class="moveleft_span1">产品编码数量</div>
                            <div class="moveleft_span2">4000万</div>
                        </div>
                    </div>
                    <div class="movetext_top">
                        <div class="movetop_left">
                            <div class="moveleft_span1">企业介绍</div>
                            <div class="moveenterprise">
                                “军民融合安全食品保障平台"是由国家工信部、国家食药局、中国农大、海军后勤部、中国农垦、中国扶贫协会、全国城市农贸中心联合会共同支持，青岛市政府、青岛食药局、黄岛区政府、黄岛食药局、青岛军民融合示范区等共同引领和指导下，于2015年8月正式成立，依托青岛军民融合食品保障有限公司承建，以“互联网+”为主要经营模式,保障军队、企事业单位、学校等集约式供给和零售端消费的“军队社会化保障、食品安全控制、“精准扶贫”服务体系建设平台”。

                            </div>
                        </div>
                    </div>
                </div>

                <!-- 企业需求 -->
                <!-- <div class="move_success">
                    <img class="movesuccess_img" src="../../assets/img/details2.png" alt="">
                </div> -->
                <!-- <div class="movesuccess_text">
                    <div class="movetext_top">
                      <div class="movetop_left">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                      <div class="movetop_right">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                  </div>
                  <div class="movetext_top demand">
                      <div class="movetop_left">
                          <div class="moveleft_span1">企业需求</div>
                          <div class="moveenterprise">
                              企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍
                          </div>
                      </div>
                  </div>
                </div> -->
                <!-- 项目成果 -->
                <!-- <div class="move_success">
                  <img class="movesuccess_img" src="../../assets/img/details2.png" alt="">
              </div> -->
                <!-- <div class="movesuccess_text">
                    <div class="movetext_top">
                      <div class="movetop_left">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                      <div class="movetop_right">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                  </div>
                  <div class="movetext_top demand">
                      <div class="movetop_left">
                          <div class="moveleft_span1">项目成果</div>
                          <div class="moveenterprise">
                              企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍
                          </div>
                      </div>
                  </div>
                </div> -->
            </div>

        </div>
        <div class="move_center" v-if="index==3" v-show="!fullWidth">
            <div class="movecenter_top">
                <span class="movecase_span">Successful cases</span>
                <span class="movespan_centet">中宠</span>
                <span class="movespan_botton"></span>
            </div>
            <!-- 详情 -->
            <div class="move_details">
                <div class="move_success">
                    <img class="movesuccess_img" src="../../assets/shouye/img3.png" alt="">
                </div>
                <div class="movesuccess_text">
                    <div class="movetext_top">
                        <div class="movetop_left">
                            <div class="moveleft_span1">所属行业</div>
                            <div class="moveleft_span2">食品行业</div>
                        </div>
                        <div class="movetop_right">
                            <div class="moveleft_span1">产品编码数量</div>
                            <div class="moveleft_span2">4000万</div>
                        </div>
                    </div>
                    <div class="movetext_top">
                        <div class="movetop_left">
                            <div class="moveleft_span1">企业介绍</div>
                            <div class="moveenterprise">
                                中宠食品有限公司是一家中外合资企业，位于烟台市莱山经济开发区。公司总投资2500万美元，占地200000平米，下设第一工厂，第二工厂，第三工厂，第四工厂和宠物饼干加工厂，另有宠物主粮项目正在筹建中。所有工厂的设计、建设按中国出口食品标准，满足所有进口国要求，管理按日本农林水产省偶蹄类肉制品注册标准，并按照HACCP食品安全管理体系管理。烟台中宠食品股份有限公司是中国最具规模、最专业化的高端	宠物食品	加工企业，公司出口五大洲，年销十亿袋，成为名符其实的全球宠物零食领军企业。

                            </div>
                        </div>
                    </div>
                </div>

                <!-- 企业需求 -->
                <!-- <div class="move_success">
                    <img class="movesuccess_img" src="../../assets/img/details2.png" alt="">
                </div> -->
                <!-- <div class="movesuccess_text">
                    <div class="movetext_top">
                      <div class="movetop_left">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                      <div class="movetop_right">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                  </div>
                  <div class="movetext_top demand">
                      <div class="movetop_left">
                          <div class="moveleft_span1">企业需求</div>
                          <div class="moveenterprise">
                              企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍
                          </div>
                      </div>
                  </div>
                </div> -->
                <!-- 项目成果 -->
                <!-- <div class="move_success">
                  <img class="movesuccess_img" src="../../assets/img/details2.png" alt="">
              </div> -->
                <!-- <div class="movesuccess_text">
                    <div class="movetext_top">
                      <div class="movetop_left">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                      <div class="movetop_right">
                          <div class="moveleft_span1"></div>
                          <div class="moveleft_span2"></div>
                      </div>
                  </div>
                  <div class="movetext_top demand">
                      <div class="movetop_left">
                          <div class="moveleft_span1">项目成果</div>
                          <div class="moveenterprise">
                              企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍企业介绍
                          </div>
                      </div>
                  </div>
                </div> -->
            </div>

        </div>
        <Footer :full='fullWidth'/>
    </div>

</template>

<script>
    // @ is an alias to /src

    import Header from "@/components/Header.vue";
    import Footer from '@/components/Footer.vue'

    export default {
        name: "Home",
        components: {
            Header,
            Footer
        },

        data() {
            return {
                index: 0,
                // 轮播图
                imgs: [
                    {id: 1, url: require('../../assets/img/banner/case.png')},
                    {id: 1, url: require('../../assets/img/banner/move_case.png')},
                ],
                imgList: [
                    {url: require('../../assets/img/case/img.png')},
                    {url: require('../../assets/img/case/img2.png')},
                    {url: require('../../assets/img/case/img3.png')},
                ],
                fullWidth: true,
                active: 0,
            }
        },
        created() {
            this.index = this.$route.query.id
        },
        methods: {
            page_width() {//获取屏幕宽度
                let screenWidth = window.screen.width;
                console.log(screenWidth);
                if (screenWidth < 800) {
                    this.fullWidth = false;
                } else {
                    this.fullWidth = true;
                }

            },
            setRem() {
                var whdef = 100 / 1920;// 表示1920的设计图,使用100PX的默认值
                var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
                var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
                document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
            },
            //划入划出
            changeActive($event) {
                this.active = 10;
                $event.currentTarget.className = "float_img active";
            },
            removeActive($event) {
                $event.currentTarget.className = "float_img";
            }
        },
        mounted() {
            window.onresize = () => {//监听屏幕变化
                this.page_width();
                this.setRem()
            };
            this.page_width();
            this.setRem()
        },

    };
</script>
<style scoped>
    .me {
        background: #f0f2f5;
    }

    /* PC端样式 */
    /* 头 */
    .case_center {
        display: flex;
        flex-direction: column;
        width: 1200px;
        margin: 60px auto 0;
    }

    .center_top {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .case_span { /* width: 377px; */
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #E02222;
        line-height: 25px;
    }

    .span_centet {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #474747;
        line-height: 25px;
        margin: 11px 0 10px 0;
    }

    .span_botton {
        width: 79px;
        height: 8px;
        background: #DDDDDD;
        border-radius: 4px;
    }

    /* 详情 */
    .case_details {
        display: flex;
        margin-top: 57px;
        margin-bottom: 102px;
    }

    .success_img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .success {
        width: 438px;
        height: 438px;
        background: #D8D8D8;
        border-radius: 10px;
        border: 1px solid #979797;
        margin-right: 175px;
    }

    .success_text {
        display: flex;
        flex-direction: column;
    }

    .text_top {
        display: flex;
    }

    .top_left {
        width: 100px;
    }

    .top_right {
        width: 200px;
    }

    .left_span1 {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
        margin-bottom: 8px;
    }

    .left_span2 {
        margin-bottom: 21px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252525;
        line-height: 17px;
    }

    .enterprise {
        width: 511px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252525;
        line-height: 27px;
    }

    /* 悬浮 */
    .float {
        height: 436px;
        background: #7e7e7e;
        display: flex;
        justify-content: center;
        margin-bottom: 130px;
    }

    .zhai_img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .float_img {
        width: 341px;
        height: 438px;
        opacity: 0.5;
    }

    .active {
        width: 417px;
        height: 530px;
        transition: all .2s linear;
        margin-top: -30px;
        z-index: 99;
        opacity: 1;
    }

    .active .zhai_img {
        z-index: 99;
    }

    /* 移动端样式 */
    /* 头 */
    .move_center {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        background: #f0f2f5;
    }

    .movecenter_top {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .moveme_span {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #E02222;
        line-height: 17px;
    }

    .movespan_centet {
        margin-top: 5px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #474747;
        line-height: 17px;
    }

    .movespan_botton {
        width: 40px;
        height: 4px;
        background: #DDDDDD;
        border-radius: 4px;
        margin-top: 5px;
    }

    /* 详情 */
    .move_details {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background: #f0f2f5;
    }

    .move_success {
        width: 345px;
        height: 174px;
        margin: 22px 0 13px 0;
    }

    .movesuccess_img {
        height: 100%;
        margin: auto;
        display: block;
    }

    .movetext_top {
        display: flex;
    }

    .movetop_left {
        width: 100px;
    }

    .movetop_right {
        width: 200px;
    }

    .moveleft_span1 {
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 14px;
    }

    .moveleft_span2 {
        margin-bottom: 13px;
        height: 11px;
        font-size: 8px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252525;
        line-height: 11px;
    }

    .moveenterprise {
        margin-bottom: 21px;
        width: 315px;
        font-size: 8px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #252525;
        line-height: 17px;
    }

    .demand {
        margin-top: -35px;
    }
</style>
